<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-input
          placeholder="手机号码"
          v-model="query.phone"
          @change="getList(1)"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="姓名"
          v-model="query.userRealName"
          @change="getList(1)"
        ></el-input>
      </el-col>

      <el-col :span="6">
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          @change="changeDate"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        >
        </el-date-picker>
      </el-col>

      <el-col :span="4">
        <el-select placeholder="类型" v-model="query.detailType" clearable>
          <el-option
            v-for="(item, index) in this.const.USER_ASSETS_TYPE"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>

        <el-button type="primary" @click="doExport">用户资产导出</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="姓名" prop="realName"></el-table-column>
      <el-table-column
        label="手机号"
        prop="phone"
        align="right"
      ></el-table-column>

      <el-table-column label="扣除金额" align="right" prop="money">
        <template #default="scope">
          <span>{{ scope.row.money / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="订单号" prop="orderNo"></el-table-column>
      <el-table-column label="授权编号" prop="authNo"></el-table-column>
      <el-table-column label="交易单号" prop="tradeNo"></el-table-column>
      <el-table-column label="关闭单号" prop="closeTradeNo"></el-table-column>
      <el-table-column label="冻结单号" prop="freezePayNo"></el-table-column>
      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.DEPOSIT_PAY_TYPE"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="备注" prop="remark"></el-table-column>

      <el-table-column label="创建时间" prop="createAt"></el-table-column>
      <el-table-column label="操作" prop="operation">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="closeDeposit(scope.row)"
            >关闭押金</span
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="total, prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
  
  
  <script>
import { depositSubtractRecord, depositSubtractClose } from "@/api/order";

import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),
  data() {
    return {
      dateRange: [],
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      endTime: -1,
      expire: -1,
      query: {},
      total: 0,
    };
  },

  mounted() {
    let phone = this.$router.currentRoute._value.query.phone;
    if (phone) {
      this.query.phone = phone;
    }
    this.getList(1);
  },

  methods: {
    closeDeposit(data) {
      this.$confirm("确认要关闭订单吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        depositSubtractClose({
          orderNo: data.orderNo,
        }).then((res) => {
          this.$message("关闭成功");
        });
      });
    },

    changeDate(e) {
      console.log(e);
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1])
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.dateRange = e;
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
      this.getList(1);
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      depositSubtractRecord({ ...this.query, ...this.pages }).then((res) => {
        let list = res.data.data.records || [];
        this.total = res.data.data.total;
        this.dataSource = list;
      });
    },
  },
};
</script>
  